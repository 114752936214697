import { InformationCircleIcon, ChartBarIcon } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keynoteboard } from './components/keynoteboard/Keynoteboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import {
  GAME_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  ABOUT_GAME_MESSAGE,
  NOT_ENOUGH_NOTES_MESSAGE,
  CORRECT_MELODY_MESSAGE,
  PLAY_MELODY_TEXT,
} from './constants/strings'
import { MAX_GUESS_LENGTH, MAX_GUESSES } from './constants/guessLength'
import { isWinningMelody, solution, isSameMelody } from './lib/melodies'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'
import './App.css'
import Soundfont from 'soundfont-player'
const ALERT_TIME_MS = 5000
const context = new AudioContext()
const piano = Soundfont.instrument(context, 'acoustic_grand_piano', { gain: 5 })

function App() {
  const [currentGuess, setCurrentGuess] = useState<string[]>([])
  const [lastGuess, setLastGuess] = useState<string[]>([])
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughNotes, setIsNotEnoughNotes] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isCleanBoard, setIsCleanBoard] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [successAlert, setSuccessAlert] = useState('')
  const [failAlert, setFailAlert] = useState('')
  const [guesses, setGuesses] = useState<string[][]>(() => {
    const loaded = loadGameStateFromLocalStorage()

    if (!loaded || !isSameMelody(loaded.solution, solution)) {
      return []
    }

    let gameWasWon = false

    if (loaded.guesses && loaded.guesses.length >= 1) {
      setLastGuess(loaded.guesses[loaded.guesses.length - 1])
    }

    loaded.guesses.forEach((guess) => {
      if (isWinningMelody(guess)) {
        gameWasWon = true
      }
    })

    if (gameWasWon) {
      setIsCleanBoard(false)
      setIsGameWon(true)
    }
    if (loaded.guesses.length >= MAX_GUESSES && !gameWasWon) {
      setIsCleanBoard(false)
      setIsGameLost(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const handlePlayButton = async () => {
    const now = context.currentTime
    for (let i = 0; i < solution.length; i++) {
      handlePlayNote(solution[i], i, now)
    }
  }

  const handlePlayNote = async (
    noteValue: string,
    time: number,
    now: number
  ) => {
    piano.then(function (piano) {
      piano.play(noteValue + '4', now + time / 2, { duration: 0.5 })
    })
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    // we need to explicitly check if game is won or lost since useEffect is called on init
    if (isGameWon) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setIsCleanBoard(false)
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    } else if (isGameLost) {
      setFailAlert(CORRECT_MELODY_MESSAGE(solution.join()))
      setTimeout(() => {
        setIsCleanBoard(false)
        setFailAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost])

  const onKeynote = (value: string) => {
    setIsCleanBoard(false)
    if (
      currentGuess.length < MAX_GUESS_LENGTH &&
      guesses.length < MAX_GUESSES &&
      !isGameWon
    ) {
      setCurrentGuess([...currentGuess, value])
    }
  }

  const onDelete = () => {
    setIsCleanBoard(false)
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }
    if (!(currentGuess.length === MAX_GUESS_LENGTH)) {
      setIsNotEnoughNotes(true)
      setIsCleanBoard(false)
      return setTimeout(() => {
        setIsNotEnoughNotes(false)
      }, ALERT_TIME_MS)
    }

    const winningMelody = isWinningMelody(currentGuess)

    if (
      currentGuess.length === MAX_GUESS_LENGTH &&
      guesses.length < MAX_GUESSES
    ) {
      /* 
      note that useState and setState are asynchronous, so guesses will likely be value before set* call
      to avoid theoretical race condition, we save reference to original count before set* call
      */
      const preCheckGuessCount = guesses.length
      setGuesses([...guesses, currentGuess])
      setLastGuess([...currentGuess])
      setCurrentGuess([])
      setIsCleanBoard(true)

      if (winningMelody) {
        setStats(addStatsForCompletedGame(stats, preCheckGuessCount))
        return setIsGameWon(true)
      }
      if (preCheckGuessCount === MAX_GUESSES - 1) {
        setStats(addStatsForCompletedGame(stats, MAX_GUESSES))
        setIsGameLost(true)
      }
    }
  }

  return (
    <div className="pt-5 max-w-8xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-6">
        <h1 className="text-xl grow font-bold dark:text-white">{GAME_TITLE}</h1>
        <InformationCircleIcon
          className="h-6 w-8 cursor-pointer dark:stroke-white"
          onClick={() => {
            setIsInfoModalOpen(true)
            setIsCleanBoard(false)
          }}
        />
        <ChartBarIcon
          className="h-6 w-8 cursor-pointer dark:stroke-white"
          onClick={() => {
            setIsStatsModalOpen(true)
            setIsCleanBoard(false)
          }}
        />
      </div>
      <button
        type="button"
        className="flex mx-auto my-6 mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none ring-2 ring-offset-2 ring-blue-500 sm:text-sm"
        onClick={() => handlePlayButton()}
      >
        {PLAY_MELODY_TEXT}
      </button>

      <Grid
        guesses={guesses}
        lastGuess={lastGuess}
        currentGuess={currentGuess}
        isCleanBoard={isCleanBoard}
      />
      <Keynoteboard
        onKeynote={onKeynote}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => {
          setIsAboutModalOpen(true)
          setIsCleanBoard(false)
        }}
      >
        {ABOUT_GAME_MESSAGE}
      </button>

      <Alert message={NOT_ENOUGH_NOTES_MESSAGE} isOpen={isNotEnoughNotes} />

      <Alert message={failAlert} isOpen={failAlert !== ''} />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
    </div>
  )
}

export default App
