import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'
import { NewlyCompletedRow } from './NewlyCompletedRow'
import { MAX_GUESSES } from '../../constants/guessLength'

type Props = {
  guesses: string[][]
  currentGuess: string[]
  lastGuess: string[]
  isCleanBoard: boolean
}

export const Grid = ({
  guesses,
  currentGuess,
  lastGuess,
  isCleanBoard,
}: Props) => {
  const empties =
    guesses.length < MAX_GUESSES - 1
      ? Array.from(Array(MAX_GUESSES - 1 - guesses.length))
      : []

  let pastCompletedGuesses: string[][]

  if (guesses.length > 1) {
    pastCompletedGuesses = guesses.slice(0, -1)
  } else {
    pastCompletedGuesses = []
  }

  return (
    <div className="pb-6">
      {pastCompletedGuesses &&
        pastCompletedGuesses.map((guess, i) => (
          <CompletedRow key={i} guess={guess} />
        ))}

      {isCleanBoard ? (
        <NewlyCompletedRow guess={lastGuess} />
      ) : (
        <CompletedRow guess={lastGuess} />
      )}

      {guesses.length < MAX_GUESSES && <CurrentRow guess={currentGuess} />}

      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))}
    </div>
  )
}
