import { Cell } from './Cell'
import { MAX_GUESS_LENGTH } from '../../constants/guessLength'

type Props = {
  guess: string[]
}

export const CurrentRow = ({ guess }: Props) => {
  const emptyCells = Array.from(Array(MAX_GUESS_LENGTH - guess.length))

  return (
    <div className="flex justify-center mb-1">
      {guess.map((note, i) => (
        <Cell key={i} value={note} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  )
}
