import { KeyValue } from '../../lib/keyboard'
import { getStatuses } from '../../lib/statuses'
import { Keynote } from './Keynote'
import { ENTER_TEXT, DELETE_TEXT } from '../../constants/strings'

type Props = {
  onKeynote: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  guesses: string[][]
}

export const Keynoteboard = ({
  onKeynote,
  onDelete,
  onEnter,
  guesses,
}: Props) => {
  const charStatuses = getStatuses(guesses)

  const onClick = (value: KeyValue) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onKeynote(value)
    }
  }

  return (
    <div>
      <div className="flex justify-center mb-1">
        <Keynote value="C" onClick={onClick} status={charStatuses['C']} />
        <Keynote value="C#" onClick={onClick} status={charStatuses['C#']} />
        <Keynote value="D" onClick={onClick} status={charStatuses['D']} />
        <Keynote value="D#" onClick={onClick} status={charStatuses['D#']} />
        <Keynote value="E" onClick={onClick} status={charStatuses['E']} />
        <Keynote value="F" onClick={onClick} status={charStatuses['F']} />
        <Keynote value="F#" onClick={onClick} status={charStatuses['F#']} />
        <Keynote value="G" onClick={onClick} status={charStatuses['G']} />
        <Keynote value="G#" onClick={onClick} status={charStatuses['G#']} />
        <Keynote value="A" onClick={onClick} status={charStatuses['A']} />
        <Keynote value="A#" onClick={onClick} status={charStatuses['A#']} />
        <Keynote value="B" onClick={onClick} status={charStatuses['B']} />
      </div>
      <div className="flex justify-center">
        <Keynote width={65.4} value="ENTER" onClick={onClick}>
          {ENTER_TEXT}
        </Keynote>
        <Keynote width={65.4} value="DELETE" onClick={onClick}>
          {DELETE_TEXT}
        </Keynote>
      </div>
    </div>
  )
}
