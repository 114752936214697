import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Influenced by Wordle mania, <br></br>the universe creates a remix:{' '}
        <br></br>a melody guessing game.
      </p>
      <br></br>
    </BaseModal>
  )
}
