import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { MAX_GUESS_LENGTH } from '../../constants/guessLength'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the melody in {MAX_GUESS_LENGTH} tries. After each guess, the
        color of the tiles will change to show how close your guess was to the
        melody.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="C#" status="correct" />
        <Cell value="D#" />
        <Cell value="A" />
        <Cell value="E" status="present" />
        <Cell value="C#" status="absent" />
        <Cell value="G" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        C# note is the correct note in the correct spot.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        E note is a correct note but not in the correct spot.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        C# note is not in the melody.
      </p>

      <div className="text-slate-400 text-xs">
        <br></br>
        <p className="text-xs">
          Remember to check if your device is on silent!
        </p>
        <p>We have a companion to Daily Jingle.</p>
        <p>
          Try Jingle Shower{' '}
          <a
            className="focus:outline-none font-bold"
            href="https://shower.dailyjingle.me/"
          >
            here!
          </a>
        </p>
      </div>
    </BaseModal>
  )
}
