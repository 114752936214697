import { MAX_GUESS_LENGTH } from '../constants/guessLength'
import seedrandom from 'seedrandom'

export const isWinningMelody = (melody: string[]) => {
  return isSameMelody(melody, solution)
}

export const isSameMelody = (melody1: string[], melody2: string[]) => {
  if (melody1.length !== melody2.length) {
    return false
  }
  for (let i = 0; i < melody1.length; i++) {
    if (melody1[i] !== melody2[i]) {
      return false
    }
  }

  return true
}

const mode = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']

const generateMelody = (length: number, seed: number) => {
  let generatedMelody = []
  let index
  for (let i = 0; i < length; i++) {
    let seedStr = (
      seed +
      ':' +
      i +
      "You found the solution to every solution! Now that you've won your game, please allow others to enjoy theirs!"
    ).toString()
    let rng = seedrandom(seedStr)
    index = Math.floor(rng() * mode.length)
    generatedMelody.push(mode[index])
  }

  return generatedMelody
}

export const getMelodyOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('February 20, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs
  const generatedMelody = generateMelody(MAX_GUESS_LENGTH, index)

  return {
    solution: generatedMelody,
    solutionIndex: index,
    tomorrow: nextday,
  }
}

export const { solution, solutionIndex, tomorrow } = getMelodyOfDay()
