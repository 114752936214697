import { v4 as uuidv4 } from 'uuid'
const gameStateKey = 'gameState'
const gameStatKey = 'gameStats'

let _anonId: string = '' // private member

type StoredGameState = {
  guesses: string[][]
  solution: string[]
}

type InternalStoredGameState = {
  guesses: string[][]
  solution: string[]
  dayIndex: number
  anonId: string
}

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
}

export const getDayIndex = () => {
  const epochMs = new Date('February 23, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  return index
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  const day = getDayIndex()
  const isgs = { ...gameState, dayIndex: day, anonId: getAnonId() } // internal stored game state
  localStorage.setItem(gameStateKey, JSON.stringify(isgs))
}

export const loadGameStateFromLocalStorage = () => {
  const day = getDayIndex()
  const data = localStorage.getItem(gameStateKey)

  if (!data) {
    return null
  }

  const parsed = JSON.parse(data) as InternalStoredGameState

  if (parsed.anonId) {
    _anonId = parsed.anonId
  }

  if (parsed.dayIndex !== day) {
    return null
  } else {
    return {
      guesses: parsed.guesses,
      solution: parsed.solution,
    } as StoredGameState
  }
}

export const getAnonId = () => {
  if (!_anonId) {
    _anonId = uuidv4()
  }

  return _anonId
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  return stats ? (JSON.parse(stats) as GameStats) : null
}
