import { solution } from './melodies'

export type NoteStatus = 'absent' | 'present' | 'correct'

export type CharValue =
  | 'C'
  | 'C#'
  | 'D'
  | 'D#'
  | 'E'
  | 'F'
  | 'F#'
  | 'G'
  | 'G#'
  | 'A'
  | 'A#'
  | 'B'

export const getStatuses = (
  guesses: string[][]
): { [key: string]: NoteStatus } => {
  const charObj: { [key: string]: NoteStatus } = {}

  guesses.forEach((melody) => {
    melody.forEach((note, i) => {
      if (!solution.includes(note)) {
        // make status absent
        return (charObj[note] = 'absent')
      }

      if (note === solution[i]) {
        //make status correct
        return (charObj[note] = 'correct')
      }

      if (charObj[note] !== 'correct') {
        //make status present
        return (charObj[note] = 'present')
      }
    })
  })

  return charObj
}

export const getGuessStatuses = (guess: string[]): NoteStatus[] => {
  if (!guess) {
    return []
  }
  const splitSolution = solution
  const splitGuess = guess

  const solutionCharsTaken = splitSolution.map((_) => false)

  const statuses: NoteStatus[] = Array.from(Array(guess.length))

  // handle all correct cases first
  guess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
      return
    }
  })

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present'
      solutionCharsTaken[indexOfPresentChar] = true
      return
    } else {
      statuses[i] = 'absent'
      return
    }
  })

  return statuses
}
