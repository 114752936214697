import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { useTransition, animated } from 'react-spring'

type Props = {
  guess: string[]
}

const ANIMATION_DELAY_MS = 500
const START_POS_X = -100

export const NewlyCompletedRow = ({ guess }: Props) => {
  let modifiedNotes = guess.map((note, i) => {
    return {
      noteValue: note,
      delay: i * ANIMATION_DELAY_MS,
      y: 0,
    }
  })

  const transitions = useTransition(modifiedNotes, {
    from: { x: START_POS_X, opacity: 0 },
    enter: (item) => async (next) => {
      await next({
        x: 0,
        opacity: 1,
        delay: item.delay,
        y: item.y,
      }).catch((e) => console.log(e.length))
    },
  })

  const statuses = getGuessStatuses(guess)

  return (
    <div className="flex justify-center mb-1">
      {transitions((styles, note, transition, index) => {
        return (
          <animated.div className="inline-block" style={styles}>
            <Cell key={index} value={note.noteValue} status={statuses[index]} />
          </animated.div>
        )
      })}
    </div>
  )
}
