import { getGuessStatuses } from './statuses'
import { solutionIndex } from './melodies'
import { GAME_TITLE, JINGLE_URL } from '../constants/strings'
import { MAX_GUESSES } from '../constants/guessLength'

export const shareStatus = (guesses: string[][], lost: boolean) => {
  navigator.clipboard.writeText(
    `${GAME_TITLE} ${solutionIndex} ${
      lost ? 'X' : guesses.length
    }/${MAX_GUESSES}\n\n` +
      generateEmojiGrid(guesses) +
      '\n\n' +
      JINGLE_URL
  )
}

export const generateEmojiGrid = (guesses: string[][]) => {
  let output = guesses
    .map((guess) => {
      // map over each guess
      const status = getGuessStatuses(guess) // => an array is returned for guess ['correct', 'present', ...]
      return guess // each guess is transformed into a color array
        .map((note, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('') // color arrays are joined together
    })
    .join('\n') //

  return output
}
